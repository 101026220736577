import React from "react"

import Loadable from "@loadable/component"

import { Text, Div, Anchor, Button, Icon } from "atomize"

import { makeStyles } from "@material-ui/core/styles"

const Dialog = Loadable(() =>
  import(/* webpackPrefetch: true */ "@material-ui/core/Dialog")
)
const DialogTitle = Loadable(() =>
  import(/* webpackPrefetch: true */ "@material-ui/core/DialogTitle")
)
const DialogContent = Loadable(() =>
  import(/* webpackPrefetch: true */ "@material-ui/core/DialogContent")
)
const Tooltip = Loadable(() =>
  import(/* webpackPrefetch: true */ "@material-ui/core/Tooltip")
)
const IconButton = Loadable(() =>
  import(/* webpackPrefetch: true */ "@material-ui/core/IconButton")
)
const Close = Loadable(() =>
  import(/* webpackPrefetch: true */ "@material-ui/icons/Close")
)
const Replay = Loadable(() =>
  import(/* webpackPrefetch: true */ "@material-ui/icons/Replay")
)

const useStyles = makeStyles(() => ({
  modalHeader: {
    borderBottom: "none",
    paddingRight: "24px",
    paddingBottom: "12px",
    paddingLeft: "24px",
    "@media (max-width: 768px)": {
      paddingTop: "12px",
      paddingRight: "12px",
    },
    "@media (min-width: 769px)": {
      paddingTop: "14px",
    },
    minHeight: "16.43px",
  },
  modalTitle: {
    // margin: "0",
    // lineHeight: "1.42857143",
  },
  modalCloseButton: {
    color: "#999999",
    "@media (max-width: 768px)": {
      marginTop: "auto",
    },
    // marginTop: '-6px',
    WebkitAppearance: "none",
    padding: "0 !important",
    cursor: "pointer",
    background: "0 0",
    border: "0",
    fontSize: "inherit",
    opacity: ".9",
    textShadow: "none",
    fontWeight: "700",
    lineHeight: "1",
    float: "right",
  },
  modalClose: {
    "@media (max-width: 768px)": {
      width: "30px",
      height: "30px",
    },
    "@media (min-width: 769px)": {
      width: "24px",
      height: "24px",
    },
  },
  modalBody: {
    backgroundColor: "white",
    height: "100%",
    paddingTop: "0px !important",
    position: "relative",
    "@media (max-width: 768px)": {
      paddingRight: "0px !important",
      paddingLeft: "0px !important",
      paddingBottom: "0px !important",
      height: "100%",
    },
    "@media (min-width: 769px)": {
      paddingRight: "24px",
      paddingLeft: "24px",
      paddingBottom: "16px",
    },
  },
  modalChat: {
    borderRadius: "8px",
    height: "100%",
    "@media (max-width: 768px)": {
      width: "100% !important",
      margin: "0px !important",
      maxHeight: "100% !important",
      height: "100% !important",
    },
    "@media (max-height: 750px)": {
      margin: "0px !important",
      maxHeight: "100% !important",
      height: "100% !important",
    },
  },
}))

export default function GDPR(props) {
  const classes = useStyles()

  // _____ RENDER _____
  return (
    <Dialog
      classes={{
        paper: classes.modalChat,
      }}
      fullWidth={true}
      maxWidth={"md"}
      onEntered={() => {
        if (props.onClose) {
          props.onEnter()
        }
      }}
      open={
        typeof props.open !== "undefined" && props.open !== null
          ? props.open
          : false
      }
      onClose={() => {
        if (props.onClose) {
          props.onClose(false)
        }
      }}
      aria-labelledby="corona-navi-chat-title"
      id="webchat"
    >
      <DialogTitle
        id="corona-navi-chat-title"
        disableTypography
        className={classes.modalHeader}
        style={{ backgroundColor: "white" }}
      >
        <Tooltip title="Chat schließen">
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Schließen"
            color="inherit"
            onClick={() => {
              if (props.onClose) {
                props.onClose(false)
              }
            }}
          >
            <Close className={classes.modalClose} />
          </IconButton>
        </Tooltip>
        <IconButton
          className={classes.modalCloseButton}
          key="restart"
          aria-label="Neu starten"
          color="inherit"
          style={{ marginRight: "1rem" }}
          disabled={true}
        >
          <Replay className={classes.modalClose} />
        </IconButton>
        <Text tag="h2" textSize="subheader" textColor="medium">
          Corona Navi
        </Text>
      </DialogTitle>
      <DialogContent className={classes.modalBody}>
        <Div
          d="flex"
          align="center"
          justify="center"
          h="100%"
          w="100%"
          textAlign="center"
          flexDir="column"
        >
          <Div
            textAlign="left"
            m={{
              x: { xs: "0.5rem", sm: "3rem", md: "6rem" },
            }}
            p={{ y: { xs: "1rem", sm: "1rem", md: "0rem" } }}
          >
            <Text
              textSize={{ xs: "title", sm: "heading", md: "display1" }}
              m={{ b: "1rem" }}
            >
              Dein Corona Navi
            </Text>
            <Text textSize="subheader" m={{ b: "1rem" }}>
              Damit ich für dich relevante Informationen raussuchen kann, werde
              ich dich im Chat nach einer/deiner Postleitzahl fragen, damit ich
              weiß wo ich für dich suchen muss. Aber ich kann dich beruhigen,
              alle Daten und Eingaben speicher ich nur in der Konversation mit
              dir und lösche sie nach spätestens 7 Tagen.
            </Text>
            <Anchor href="/datenschutz" target="_blank">
              <Text textSize="subheader" m={{ b: "1rem" }}>
                Meine Datenschutzhinweise findest du hier.
              </Text>
            </Anchor>
            <Text textSize="subheader" m={{ b: "2rem" }}>
              Bitte bestätige, dass du die Datenschutzhinweise zur Kenntnis
              genommen hast.
            </Text>
            <Button
              prefix={
                <Icon
                  name={"Checked"}
                  size="16px"
                  color="white"
                  m={{ r: "0.5rem" }}
                />
              }
              bg="success700"
              hoverBg="success800"
              rounded="circle"
              p={{ r: "1.5rem", l: "1rem" }}
              m={{ x: { xs: "auto", sm: "auto", md: "0rem" } }}
              shadow="3"
              hoverShadow="4"
              onClick={() => {
                if (props.setAccepted) {
                  props.setAccepted()
                }
              }}
            >
              Bestätigen
            </Button>
          </Div>
        </Div>
      </DialogContent>
    </Dialog>
  )
}
